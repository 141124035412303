<template>
  <div class="bg">
    <div class="container">
      <div class="auth-wrapper auth-v1">
        <div class="auth-inner py-2">
          <!-- Login v1 -->
          <div class="mb-0">
            <div class="text-center">
              <b-img
                src="/icon/logo.png"
                alt="logo joker168s"
                height="75"
                class="mb-2"
              />
            </div>
            <!-- form -->
            <div class="LoginForm">
              <validation-observer ref="loginForm">
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent="forget"
                >
                  <!-- email -->
                  <b-form-group>
                    <label>เบอร์โทรศัพท์</label>
                    <validation-provider
                      #default="{ errors }"
                      name="เบอร์โทรศัพท์"
                      vid="email"
                      rules="required"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="tels"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10"
                        :state="errors.length > 0 ? false : null"
                        name="login-email"
                        size="lg"
                        placeholder="กรอกเบอร์โทรศัพท์ของท่าน"
                      />
                      <small class="text-info">{{ errors[0] }}</small>
                      <small
                        class="text-danger text-center"
                      >*รหัสผ่านใหม่จะถูกส่งไปยังเบอร์โทรศัพท์ที่ท่านลงทะเบียนไว้</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit buttons -->
                  <button
                    class="btnlogin"
                    type="submit"
                  >
                    ลืมรหัสผ่าน
                  </button>
                  <!-- <button
                  class="btn-hover color-1"
                  type="submit"
                  block
                >
                  ลืมรหัสผ่าน
                </button> -->
                  <div class="text-center mt-2">
                    <span
                      style="color: #fff"
                    >ต้องการเข้าสู่ระบบใช่ไหม?<a
                      :href="`/login`"
                      style="color: #ba995d"
                    >
                      เข้าสู่ระบบ</a></span>
                  </div>
                </b-form>
              </validation-observer>
            </div>
            <b-modal
              id="modal-sm"
              cancel-variant="secondary"
              ok-only
              ok-title="ส่งรหัสผ่าน"
              centered
              size="sm"
              title="ลืมรหัสผ่าน"
            >
              <b-input-group
                class="input-group-merge"
                title="sss"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="PhoneCallIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="tel"
                  type="number"
                  placeholder="กรุณากรอกเบอร์โทรศัพท์"
                />
              </b-input-group>
              <small>*กรุณากรอกเบอร์โทรศัพท์ที่ท่านทำการสมัครไว้</small>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  // BFormCheckbox,
  BImg,
  BForm,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    // BFormCheckbox,
    BImg,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      tels: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      Ip: null,
      // validation rules
      required,
      ag: 12,
      URLag: this.$route.query.ag,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // console.log(this.$route.query.tel)
    // console.log(this.$route.query.password)
    if (this.$route.query.tel && this.$route.query.password) {
      this.fwlogin(this.$route.query.tel, this.$route.query.password)
    }
  },
  methods: {
    async GetIp() {
      await axios
        .get('https://api.ipify.org')
        .then(response => {
          this.Ip = response.data
        })
        .catch(error => console.log(error))
    },
    forget() {
      this.username = `UFA${this.tel}`
      const formData = {
        tels: this.tels,
        agent_id: this.ag,
      }
      this.$http
        .post('https://api.ufabangkok.com/api/forgetpass', formData)
        // .post('https://api.ufabangkok.com/api/forgetpass', formData)
        .then(response => {
          this.Success(response.data.message)
          this.$router.push({ name: 'auth-login' })
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              Detail: {
                Ip: this.Ip,
                UserAgent: navigator.userAgent,
                Platform: navigator.platform,
              },
            })
            .then(response => {
              const userData = response.data
              // console.log(response.data)
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('CheckClick', true)
              // localStorage.setItem('token', JSON.stringify(userData).token)
              // localStorage.setItem('userData', JSON.parse(userData))
              this.$ability.update(userData.ability)
              this.$http
                .get('https://api.ufabangkok.com/api/auth/checklogin')
                .then(res => {
                  localStorage.setItem('CountLogin', res.data)
                })

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
                  icon: 'LockIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            })
        }
      })
    },
    fwlogin(tel, pass) {
      useJwt
        .login({
          email: tel,
          password: pass,
          Detail: {
            Ip: this.Ip,
            UserAgent: navigator.userAgent,
            Platform: navigator.platform,
          },
        })
        .then(response => {
          const userData = response.data
          // console.log(response.data)
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          // localStorage.setItem('token', JSON.stringify(userData).token)
          // localStorage.setItem('userData', JSON.parse(userData))
          this.$ability.update(userData.ability)

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          this.$router
            .push(getHomeRouteForLoggedInUser(userData.role))
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
              icon: 'LockIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
label {
  color: #ba995d;
}
.bg {
  background-image: url(/bgh.jpg);
  background-size: cover;
  background-position: center right;
}
.LoginForm {
    width: 100%;
    /* margin: auto; */
    padding: 11px 15px 31.5px;
    /* opacity: 0.59; */
    border-radius: 10px;
    background-color: #050202;
    margin-top: 40px;
    background: rgba(0,0,0,0.59);
}
.btnlogin {
    padding: 0.75em;
    background: linear-gradient(to bottom, #e7d39d, #b19560);
    color: black;
    border-radius: 5em;
    width: 100%;
    height: 43px;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    text-align: center;
}
.warning-show {
  color: #fff;
  text-align: center;
  background: linear-gradient(to bottom, #7222416e, #58585870);
  border-radius: 10px;
  padding: 15px;
  border: rgba(255, 255, 255, 0.527) solid 4px;
  box-shadow: #8826ff94 0px 5px 10px 5px;
}

</style>
<style lang="scss" scoped>
.form-control {
  padding: 0.438rem 1rem;
  background: #fff !important;
  background-clip: padding-box;
  border: 0;
  height: 43px;
  border-radius: 5em;
}
.input-group-text {
  padding: 0.438rem 1rem;
  height: 43px;
  margin-bottom: 0;
  text-align: center;
  background: white !important;
  border: 0;
  border-radius: 5em;
}

</style>
